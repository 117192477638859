<template>
  <div class="app-wrap">
    <h3>
      <i
        class="iconfont icon-a-fundmarkect-back"
        @click="handleReturn"
      />
      银行卡管理
    </h3>
    <div class="form-wrap">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-position="top"
        label-width="80px"
      >
        <el-form-item
          class="card-item"
          label="银行名称"
          prop="bankNo"
        >
          <el-select
            v-model="form.bankNo"
            class="pw-inpput"
            placeholder="请选择"
          >
            <template slot="prefix">
              <div class="bank-img_wrap">
                <img :src="(selectBank || {}).logoUrl">
              </div>
            </template>
            <el-option
              v-for="item in bankList"
              :key="item.bankNo"
              :label="item.bankName"
              :value="item.bankNo"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          class="card-item"
          label="银行卡号"
          prop="bankAccount"
        >
          <el-input
            v-model="form.bankAccount"
            class="pw-inpput"
          />
        </el-form-item>
        <el-form-item
          class="card-item"
          label="银行预留手机号码"
          prop="bankPhone"
        >
          <el-input
            v-model="form.bankPhone"
            class="pw-inpput"
          />
        </el-form-item>
        <el-form-item
          class="card-item"
          label="短信验证码"
          prop="code"
        >
          <el-input
            v-model="form.code"
            class="pw-inpput"
            style="width:320px !important"
          />
          <span
            class="code-b"
            :class="sendCodeDisabled ? 'disabled' : ''"
            @click="sendCode('sendCodeDisabled', 'sendCodeText')"
          >{{ sendCodeText }}</span>
        </el-form-item>
        <p style="color:#f00; line-height: 2em;">{{ bindError }}</p>
        <el-form-item
          class="card-item"
          style="margin-top:44px"
        >
          <span
            :class="['confirm-btn',{'disable':!bindabel}]"
            @click="handleConfirm"
          >确认绑定</span>
          <span
            class="cancel-btn"
            @click="handleReturn"
          >返回</span>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
// bindSmsSign
  import { getPayChannel, bindSmsSign, bindVerifCodeForBank } from "@/api/bankmanagement";
  import { bankCardCheck, phoneCheck} from '@/utils/checkReg.js'
  export default {
    data() {
      return {
        sendCodeDisabled: false,
        sendCodeText: '获取验证码',
        bankList: [],
        userInfo: {},
        form: {
          bankNo: '',
          bankAccount: '',
          bankPhone: '',
          code: ''
        },
        rules: {
          bankNo: [
            { required: true, message: '请选择银行', trigger: 'blur' }
          ],
          bankAccount: [
            { required: true, message: '请输入银行卡号', trigger: 'blur' },
            { pattern: bankCardCheck, message: '银行卡号格式不正确' }
          ],
          bankPhone: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
            { pattern: phoneCheck, message: '手机号格式不正确' }
          ],
          code: [
            { required: true, message: '请输入验证码', trigger: 'blur' },
          ]
        },
        bindError: '',
        bindabel: true,
        src: '', //绑卡起源 , 1:老卡绑定
        capitalMode: ''
      }
    },
    computed: {
      selectBank() {
        return this.bankList.find(item => item.bankNo === this.form.bankNo)
      }
    },
    created () {
      this.userInfo = JSON.parse(localStorage.getItem('customerInfo')) || {}
      getPayChannel().then(({data}) => {
        this.bankList = data || []
        this.form.bankNo = this.bankList[0].bankNo
        if(this.$route.params.src == '1'){
          this.src = this.$route.params.src
          this.form.bankNo = this.$route.params.bankNo || ''
          this.form.bankAccount = this.$route.params.bankAccount || ''
          this.capitalMode = this.$route.params.capitalMode || ''
        }
      })
    },
    methods: {
      sendCode(disabled, text)  {
        if (this[disabled]) {
          return
        }
        this.$refs.form.validateField('bankNo',error => {
          if(error) {
            return
          }
          this.$refs.form.validateField('bankAccount',error => {
            if(error) {
              return
            }
            this.$refs.form.validateField('bankPhone',error => {
              if(error) {
                return
              }              
              this[text] = '正在发送……'
              this[disabled] = true
              bindSmsSign({
                bankAccount: this.form.bankAccount,
                bankAccountName: this.userInfo.clientName,
                bankName: this.selectBank.bankName,
                bankNo: this.selectBank.bankNo,
                bankPhone: this.form.bankPhone,
                capitalMode: this.src === '1' ? this.capitalMode : this.selectBank.capitalMode
              }).then(res => {
                console.log('res',res)
                this.countDown(disabled, text)
              }).catch(error => {                
                this[text] = '获取验证码'
                this[disabled] = false
                this.$message({
                  type: 'error',
                  center: 1,
                  showClose: 1,
                  duration:5000,
                  message: error
                });
              })
            })
          })
        })      
      },
      countDown(disabled, text) {
        let num = 60
        this[text] = `${num}s后重新获取`
        const timer = setInterval(() => {
          console.log(num)
          num--
          if (num < 1) {
            clearInterval(timer)
            this[text] = `获取验证码`
            this[disabled] = false
          } else {
            this[text] = `${num}s后重新获取`
          }
        }, 1000);
        this.$once('hook:destroyed', () => {
          clearInterval(timer)
        })
      },
      handleConfirm() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            const _this = this
            this.bindError = ''
            if(!this.bindabel) {
              return
            }
            this.bindabel = false
            bindVerifCodeForBank({
              bankAccount: this.form.bankAccount,
              bankAccountName: this.userInfo.clientName,
              bankName: this.selectBank.bankName,
              bankNo: this.selectBank.bankNo,
              bankPhone: this.form.bankPhone,
              capitalMode:this.src === '1' ? this.capitalMode : this.selectBank.capitalMode,
              bankPhoneCode: this.form.code
            }).then(() => {
              this.$message({
                message: '请输入交易密码！',
                type: 'success',
                onClose() {
                  if(_this.src == '1') {
                    _this.push({name:'ProcessPurchase'})
                  }else {
                    _this.$router.go(-1)
                  }
                }
              });
            }).catch(error => {
              this.bindError = error
            }).finally(() => {
              this.bindabel = true
            })
          }
        })
      },
      handleReturn() {
        this.$router.go(-1)
      }
    },
  }
</script>

<style lang="less" scoped>
@import url('@/assets/css/zc-common.less');
.app-wrap > h3 {
  .flex(flex-start, center, row);
  font-size: 20px;
  color: #1F1F1F;
  padding-bottom: 30px;
  border-bottom: 1px solid #EDF1F7;
  font-weight: normal;
  i {
    font-size:18px;
    color: #BDC0CB;
    margin-right: 14px;
  }
}
.form-wrap {
  width: 100%;
  padding-top: 68px;
}
.bank-img_wrap {
  width: 100%;
  height: 100%;
  .flex(center, center, row);
  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
}
.confirm-btn {
  .btn(240px, 48px)
}
.cancel-btn {
  font-size: 16px;
  color: #CE9B63;
  margin-left: 33px;
  cursor: pointer;
}
.code-b {
  .inline-flex(center, center, row);
  width: 155px;
  height: 50px;
  border: 0.6px solid #CE9B63;
  border-radius: 4px;
  color: #CE9B63;
  font-size: 18px;
  margin-left: 24px;
  cursor: pointer;
  &.disabled {
    border: 0.6px solid #BDC0CB;
    color: #BDC0CB;
  }
}
</style>
<style>
.pw-inpput {
  width:520px !important;
  height:50px !important
}
.pw-inpput .el-input__inner {
  height:50px !important;
  line-height: 50px !important;
}
.pw-inpput .el-input__suffix {
  right: 20px !important;
  display: flex;
  align-items: center;
}
.input-with-select .el-input-group__prepend {
  width: 65px !important;
}
.card-item .el-form-item__label{
  font-size: 14px !important;
  color: #BDC0CB !important;
  padding: 0 !important;
}
.card-item .el-form-item__content {
  display: flex !important;
  align-items: center;
}
</style>